import { NgModule } from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';

// Extra Config
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routes: Routes = [
  {path: 'about',loadChildren: () => import('./core/about/about.module').then(m => m.AboutModule)},
  {path: 'register',loadChildren: () => import('./core/register/register.module').then(m => m.RegisterModule)},
  {path: 'privacy-policy',loadChildren: () => import('./core/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
  {path: '', loadChildren: () => import('./core/landing/landing.module').then(m => m.LandingModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
