import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "./core/core.module";
import {GraphQLModule} from './graphql.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {httpTranslateLoader} from './core/translate/translate-loader-factory';
import {LanguageModule} from "./modules/language/language.module";
import {CURRENCY_MASK_CONFIG} from "ng2-currency-mask";
import {registerLocaleData} from "@angular/common";
import {AuthInterceptor} from "./core/auth/auth.interceptor";

import localePt from '@angular/common/locales/pt';
import {SlickCarouselModule} from "ngx-slick-carousel";
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    CoreModule,
    LanguageModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CoreModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR' // pt-BR | en-US | es-ES
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL' // BRL | USD | EUR
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useFactory: getCurrencyTag,
      // useValue: 'BRL' // BRL | USD | EUR
    },
    {
      provide: CURRENCY_MASK_CONFIG, useValue: {   // BRL
        align: 'left',
        allowNegative: true,
        decimal: ',',
        precision: 2,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getCurrencyTag() {
  return getMeta('store-currency', 'BRL');
}

function getMeta(metaName: string, defaultValue: string) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return defaultValue;
}


